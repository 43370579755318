<script>
import config from '@/vue.config';

export default {
    name: 'Login',

    data: () => ({
        form: {
            mail: '',
            password: '',
            admin: 1,
        },
    }),

    methods: {
        login: function() {
            this.axios
                .get(config.store.auth.login, { params: this.getForm() })
                .then(response => {
                    this.$cookie.set(
                        'auth_token_admin',
                        response.data.data.tokens.auth_token,
                    );
                    this.axios.defaults.headers.common['Authorization'] =
                        response.data.data.tokens.auth_token;
                    this.$alertify.success('successfully logged in');
                    this.$router.push('/pages/dashboard');
                })
                .catch(error => {
                    this.$alertify.error(error.response.data.message);
                });
        },

        getForm: function() {
            return this.form;
        },
    },
};
</script>
<template>
    <div class="main-logo__container">
        <div class="main-logo__outer">
            <img width="150" src="../../../assets/logo-pirin.svg" alt="logo" />
            <v-form @submit.prevent="login">
                <v-text-field
                    v-model="form.mail"
                    label="E-mail"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="form.password"
                    label="Password"
                    type="password"
                    required
                ></v-text-field>
                <v-btn
                    class="main-logo__button"
                    type="submit"
                    elevation="2"
                    small
                    >Login
                </v-btn>
            </v-form>
            <router-view></router-view>
        </div>
    </div>
</template>
<style scoped>
.main-logo__container {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/login_bg.jpeg');
    display: flex;
    align-items: center;
}

.main-logo__outer {
    display: table;
    width: 400px;
    height: 450px;
    padding: 15px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.621);
    box-shadow: 3px 3px 10px #333;
    text-align: center;
}

.main-logo__button {
    margin: 0 !important;
    width: 50%;
    background: rgba(128, 128, 128, 0.142);
    font-size: 14px;
    letter-spacing: 5px;
    font-weight: 500;
    cursor: pointer;
}
</style>
